import React from "react";

const Loading = () => {
  return (
    <div className="ui active dimmer">
      <div className="ui larger text loader">Nahrávám</div>
    </div>
  );
};
export default Loading;
